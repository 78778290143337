import React from 'react';
import { Link } from 'gatsby';

import * as styles from '../css/pages/PageNotFound.module.scss';

import Layout from '../components/layout/Layout';
import SEO from '../components/layout/SEO';

const PageNotFound = () => {
    return (
        <>
            <SEO title="Page not found" />
            <Layout mainClass={styles.PageNotFound}>
                <section className={styles.intro}>
                    <h1>Sorry, that page doesn't exist</h1>
                    <hr />
                    <p>Were you looking for one of these?</p>
                    <div className={styles.linkContainer}>
                        <Link to="/about/">About Ripples</Link>
                        <Link to="/charities/">Charities</Link>
                        <Link to="/schools/">Schools</Link>
                        <Link to="/join/">Join Ripples</Link>
                    </div>
                    <p>
                        Or go back <Link to="/">home</Link>
                    </p>
                </section>
            </Layout>
        </>
    );
};

export default PageNotFound;
